button.fc-myCustomButton-button.fc-button.fc-button-primary {
  background-color: var(--social_bg) !important;
  border: none;
  color: var(--texts) !important;
  border-radius: 10px;
  font-weight: 700;
}
button.fc-createWebinar-button.fc-button.fc-button-primary {
  background-color: var(--social_bg) !important;
  border: none;
  color: var(--texts) !important;
  border-radius: 10px;
  font-weight: 700;
}
button.fc-buttonReady-button.fc-button.fc-button-primary {
  background-color: var(--social_bg) !important;
  border: none;
  color: var(--texts) !important;
  border-radius: 10px;
  font-weight: 700;
}
button.fc-today-button.fc-button.fc-button-primary {
  background-color: var(--bg-button) !important;
  border: none;
  color: #fff;
  border-radius: 10px;
  font-weight: 700;
}
button.fc-prev-button.fc-button.fc-button-primary {
  background-color: var(--bg-button) !important;
  border: none;
  color: #fff;
  border-radius: 10px;
  font-weight: 700;
}
button.fc-next-button.fc-button.fc-button-primary {
  background-color: var(--bg-button) !important;
  border: none;
  color: #fff;
  border-radius: 10px;
  font-weight: 700;
}
span.fc-icon.fc-icon-chevron-left {
  font-weight: 900 !important;
  font-size: 1.2rem !important;
}
span.fc-icon.fc-icon-chevron-right {
  font-weight: 900 !important;
  font-size: 1.2rem !important;
}
.event-custom {
  background-color: var(--bg-button);
  color: #fff;
  border-radius: 7px !important;
}
.event-custom-success {
  background-color: var(--social_bg);
  color: var(--texts);
  border-radius: 7px !important;
}
.input-title {
  width: 100%;
  margin-bottom: 15px;
  height: 30px;
  border: 1px solid rgba(128, 128, 128, 0.315);
  border-radius: 5px;
}
.display-flex {
  display: flex;
  flex-direction: column;
}
.buttons-already {
  display: flex;
  width: 100%;
  justify-content: space-between;
}

.buttons-already button {
  width: 100%;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}
.buttons-already button:nth-child(1) {
  background-color: var(--bg-button);
  color: #fff;
  font-weight: 700;
}
.buttons-already button:nth-child(2) {
  background-color: var(--social_bg);
  color: var(--texts);
  font-weight: 700;
}

.popup-buttons {
  margin-top: 15px;
  width: 100%;
  display: flex;
  justify-content: center;
  gap: 20px;
}

.popup-buttons .confirm-button {
  background-color: var(--social_bg) !important;
  border: none;
  color: var(--texts) !important;
  border-radius: 10px;
  font-weight: 700;
  padding: 5px;
}
.recordings {
  height: 100px;
  padding: 10px;
  overflow-y: scroll;
}

.text-recording {
  display: block;
  color: var(--texts) !important;
  text-align: left;
  font-weight: 500 !important;
  font-size: 1.2rem;
}
